<template>
  <v-theme-provider dark>
    <base-section
      id="info-alt"
      class="grey darken-4 pb-0"
    >
      <v-container>
        <v-row>
          <v-col class="d-flex justify-center align-center">
            <base-info />
          </v-col>
        </v-row>
        <v-row>
          <v-col :class="`d-flex justify-center flex-wrap ${$vuetify.breakpoint.smAndDown ? ' align-center flex-column' : 'align-end'}`">
            <div class="d-flex justify-center pt-6">
              <a
                href="https://sgd-group.com/"
                target="_blank"
              >
                <img
                  :src="require('@/assets/imgs/sgd/logo.png')"
                  height="60"
                  class="mx-8"
                />
              </a>
              <a
                href="https://minnovi.com"
                target="_blank"
              >
                <img
                  :src="require('@/assets/imgs/mnn/logoWhite.png')"
                  height="60"
                  class="mr-4"
                />
              </a>

            </div>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionBusinessInfo',
  }
</script>
